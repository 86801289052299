const g_d = {};

//common constants
g_d.env_mode = "pro";//dev pro
g_d.dev = "dev";
g_d.pro = "pro";
g_d.domain = "https://www.lingodeer.cn";

if (g_d.env_mode === g_d.dev) {
    g_d.domain = "localhost:8080";
}

//comp pic
g_d.webSourceUrl = "/webSource/";
g_d.imgsBaseUrl = g_d.webSourceUrl + "imgs/";
g_d.landPagePic = {
    lottieGif: {
        lottie_gif_1: g_d.imgsBaseUrl + "lottieGif/lottie1.gif",
        lottie_gif_2: g_d.imgsBaseUrl + "lottieGif/lottie2.gif",
        lottie_gif_3: g_d.imgsBaseUrl + "lottieGif/lottie3.gif"
    },
    panel_1_bg: g_d.imgsBaseUrl + "panel_1_bg.png",
    panel_1_phone_bg: g_d.imgsBaseUrl + "panel_1_phone_bg.png",
    bigTitleImg: g_d.imgsBaseUrl + "bigTitleImg.png",
    popRect: {
        close: g_d.imgsBaseUrl + "popRect/close.png",
        top: g_d.imgsBaseUrl + "popRect/top.png"
    },
    scanCodeAd: g_d.imgsBaseUrl + "scanCodeAd.gif",
    flagBase: g_d.imgsBaseUrl + "flag/",
    lans_desc: g_d.imgsBaseUrl + "lans_desc/",
    super_function: g_d.imgsBaseUrl + "super_function/",
    platform: g_d.imgsBaseUrl + "platform/",
    user_comment: g_d.imgsBaseUrl + "user_comment/",
    learning: g_d.imgsBaseUrl + "learning.png",
    yh: g_d.imgsBaseUrl + "yh.png",

    logo1: g_d.imgsBaseUrl + "logo1.png",
    logo2: g_d.imgsBaseUrl + "logo2.png",
    logo: g_d.imgsBaseUrl + "logo.png",
    android_icon: g_d.imgsBaseUrl + "android_icon.png",
    iphone_icon: g_d.imgsBaseUrl + "iphone_icon.png",

    android_icon_1: g_d.imgsBaseUrl + "android_icon_1.png",
    iphone_icon_1: g_d.imgsBaseUrl + "iphone_icon_1.png",

    arrow_left: g_d.imgsBaseUrl + "arrow_left.png",
    arrow_right: g_d.imgsBaseUrl + "arrow_right.png",

    starLight: g_d.imgsBaseUrl + "starLight.png",

    ios: g_d.imgsBaseUrl + "ios.png",
    android: g_d.imgsBaseUrl + "android.png",

    kf: g_d.imgsBaseUrl + "kf.png",
    gzh: g_d.imgsBaseUrl + "gzh.png",
    load_ios_android: g_d.imgsBaseUrl + "load_ios_android.png",
    second_rank: {
        zp: {
            a: g_d.imgsBaseUrl + "second_rank/zp/1.png",
            b: g_d.imgsBaseUrl + "second_rank/zp/2.png"
        },
        tj: {
            a: g_d.imgsBaseUrl + "second_rank/tj/1.png",
            b: g_d.imgsBaseUrl + "second_rank/tj/2.png",
            c: g_d.imgsBaseUrl + "second_rank/tj/3.png"
        },
        js: {
            a: g_d.imgsBaseUrl + "second_rank/js/1.png",
            b: g_d.imgsBaseUrl + "second_rank/js/2.png",
            c: g_d.imgsBaseUrl + "second_rank/js/3.png",
            d: g_d.imgsBaseUrl + "second_rank/js/4.png"
        },
        jx: {
            a: g_d.imgsBaseUrl + "second_rank/jx/1.png",
            b: g_d.imgsBaseUrl + "second_rank/jx/2.png",
            c: g_d.imgsBaseUrl + "second_rank/jx/3.png",
            d: g_d.imgsBaseUrl + "second_rank/jx/4.png",
            e: g_d.imgsBaseUrl + "second_rank/jx/5.png",
            f: g_d.imgsBaseUrl + "second_rank/jx/6.png"
        },
        sw: {
            a: g_d.imgsBaseUrl + "second_rank/sw/1.png"
        }
    }
};

//remote pic config
if (g_d.env_mode === g_d.pro) {
    let cdn_pics = {
        "learning": "https://common.lingodeer.cn/llss_home_config/learning.png",
        "android": "https://common.lingodeer.cn/llss_home_config/android.png",
        "gzh": "https://common.lingodeer.cn/llss_home_config/gzh.png",
        "ios": "https://common.lingodeer.cn/llss_home_config/ios.png",
        "kf": "https://common.lingodeer.cn/llss_home_config/kf.png",
        "load_ios_android": "https://common.lingodeer.cn/llss_home_config/load_ios_android.png",
        "panel_1_bg": "https://common.lingodeer.cn/llss_home_config/panel_1_bg.png",
        "panel_1_phone_bg": "https://common.lingodeer.cn/llss_home_config/panel_1_phone_bg.png",
        "scanCodeAd": "https://common.lingodeer.cn/llss_home_config/scanCodeAd.gif",
        "lottie1": "https://common.lingodeer.cn/llss_home_config/lottie1.gif",
        "lottie2": "https://common.lingodeer.cn/llss_home_config/lottie2.gif",
        "lottie3": "https://common.lingodeer.cn/llss_home_config/lottie3.gif"
    };
    g_d.landPagePic = Object.assign({}, g_d.landPagePic, cdn_pics);
    g_d.landPagePic.lottieGif = {
        lottie_gif_1: cdn_pics.lottie1,
        lottie_gif_2: cdn_pics.lottie2,
        lottie_gif_3: cdn_pics.lottie3
    }
}

//generate preloadResourcesArray
g_d.preloadResourcesArray = [];
iterateJson(g_d.landPagePic);

function iterateJson(jsonObj) {
    for (let key in jsonObj) {
        let tempObj = jsonObj[key];

        if (typeof tempObj === 'object') {
            iterateJson(tempObj);
        } else {
            g_d.preloadResourcesArray.push(tempObj);
        }
    }
}

export default g_d;