<template>
    <div class="business">
        <div class="listArea">
            <div class="item">
                <div class="wrapItem">
                    <div>
                        <img class="picA" :src="swPic.a">
                    </div>
                    <div class="contentArea">
                        <div class="titleT">
                            商务合作
                        </div>
                        <div class="contentT">
                            <div>
                                您好，商务合作请发送邮件至<a href="mailto:bd@lingodeer.com.cn"> bd@lingodeer.com.cn</a>，期待与您的合作！
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./Business.scss" lang="scss"></style>

<script>
    import Constants from "../../../../utils/Constants"

    export default {
        data() {
            return {
                swPic: Constants.landPagePic.second_rank.sw,
            }
        }
    }
</script>

